'use client'

import classnames from 'classnames'
import styles from './NavigationButtons.module.scss'
import useI18n from '@/hooks/use-i18n'
import Button from '@/components/Button/Button'
import { DOC_TYPES, LOCATIONS_SLUG } from '@/data'
import useStore from '@/store'
import OrderDropdown from '@/components/OrderDropdown/OrderDropdown'
import useCurrentPage from '@/hooks/use-current-page'
import useBreakpoint from '@/hooks/use-breakpoint'

type NavigationButtonsProps = {
  className?: string
  currentScrollingOverSection: string
}

const HIDDEN_BUTTONS_OVER_SECTIONS = ['homepageHero']

const NavigationButtons = ({ className, currentScrollingOverSection }: NavigationButtonsProps) => {
  const { i18n } = useI18n()
  const activeRestaurant = useStore(state => state.activeRestaurant)
  const favouriteRestaurant = useStore(state => state.favouriteRestaurant)
  const { currentSlug } = useCurrentPage()
  const setNavIsOpen = useStore(state => state.setNavIsOpen)
  const { isMobile } = useBreakpoint()

  let restaurantToUse = favouriteRestaurant || activeRestaurant

  if (activeRestaurant) {
    restaurantToUse = activeRestaurant
  }

  const hasHiddenButtonsOverSection =
    !restaurantToUse && !isMobile && HIDDEN_BUTTONS_OVER_SECTIONS.includes(currentScrollingOverSection)

  return (
    <div
      className={classnames(styles.NavigationButtons, className, {
        [styles.hidden]: (currentSlug === LOCATIONS_SLUG && !favouriteRestaurant) || hasHiddenButtonsOverSection,
      })}
    >
      {!restaurantToUse ? (
        <>
          <Button
            secondary
            icon="clock"
            link={{
              label: i18n('reserve'),
              linkType: 'internal',
              link: {
                _type: DOC_TYPES.PAGE,
                slug: process.env.NEXT_PUBLIC_IS_CANADA_WEBSITE ? 'locations/vancouver' : LOCATIONS_SLUG,
              },
            }}
            onClick={() => {
              setNavIsOpen(false)
            }}
          />
          <Button
            secondary
            icon="caretRight"
            link={{
              label: i18n('order'),
              linkType: 'internal',
              link: {
                _type: DOC_TYPES.PAGE,
                slug: process.env.NEXT_PUBLIC_IS_CANADA_WEBSITE ? 'locations/vancouver' : LOCATIONS_SLUG,
              },
            }}
            onClick={() => {
              setNavIsOpen(false)
            }}
          />
        </>
      ) : (
        <OrderDropdown
          onLinkClick={() => {
            setNavIsOpen(false)
          }}
          serviceLinks={restaurantToUse ? [...(restaurantToUse.reservationLinks || []), ...(restaurantToUse.serviceLinks || [])] : []}
          dropdownPosition={isMobile ? 'bottomLeft' : 'bottomRight'}
          className={styles.orderDropdown}
          label={
            restaurantToUse.areaTitle
              ? i18n('locationLongName', { title: restaurantToUse.title, areaTitle: restaurantToUse.areaTitle })
              : restaurantToUse.title
          }
          isSoftOpening={restaurantToUse.isSoftOpening}
        />
      )}
    </div>
  )
}

NavigationButtons.displayName = 'NavigationButtons'

export default NavigationButtons
