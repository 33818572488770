import {
  DOC_TYPES,
  LOCATIONS_SLUG,
  MENU_SLUG,
} from '@/data'
import { geti18nText } from '@/hooks/use-i18n'
import { SectionsSchema } from '@/types/components/SectionsProps'

// TODO: UPDATE THIS IMPORT FROM 'utils' TO 'utils/url' once that file is created
import { getMenuItemListingGroupId } from '@/utils'

export const formatPageSections = (sections: SectionsSchema) => {
  if (!sections?.length) return sections

  // eslint-disable-next-line
  const sectionsFormatted: SectionsSchema = []

  // eslint-disable-next-line
  sections.forEach((section: any) => {
    if (section.draft) {
      sectionsFormatted.push(section.draft)
    } else {
      sectionsFormatted.push(section)
    }
  })

  return sectionsFormatted as SectionsSchema
}


export const getSections = (data: SanityPage, language: string) => {
  if (data._type === 'page') {
    return data.sections
  }

  if (data._type === 'menuItem' || data._type === 'location' || data._type === 'blogPost') {
    const newSections: any[] = []

    if (data._type === 'menuItem') {
      const title = data?.title || ''

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let allowedStaggeredImages: any[] = []
      let combinedImages: SanityImage[] = []

      if (data?.menuItemData?.lifestyleImages?.length) {
        combinedImages = [...combinedImages, ...data?.menuItemData?.lifestyleImages]
      }

      if (data?.menuItemData?.images?.length) {
        combinedImages = [...combinedImages, ...data?.menuItemData?.images]
      }

      const mainImage = combinedImages[0]

      if (data?.menuItemData?.lifestyleImages?.length) {
        data?.menuItemData?.lifestyleImages.forEach(image => {
          if (image?.asset?.url !== mainImage?.asset?.url) {
            allowedStaggeredImages.push(image)
          }
        })
      }

      allowedStaggeredImages = allowedStaggeredImages?.map(image => ({ mediaType: 'image', image }))

      const menuItemHero = {
        section: [
          {
            _type: 'textAndImageHeroWithBreadcrumbs',
            _id: 'textAndImageHeroWithBreadcrumbs',
            cmsSettings: {
              isHidden: false,
              cmsTitle: '',
            },
            sectionBackground: 'dough' as SectionBackground,
            title: title,
            subtitle: data?.menuItemData?.subtitle || '',
            description: data?.menuItemData?.description || '',
            images: combinedImages,
            slug: data?.slug?.current,
            mediaStyle: data?.menuItemData?.heroImageStyle || 'roundedCorners',
            breadcrumbLink1: {
              label: geti18nText(language, 'menu'),
              linkType: 'internal',
              link: {
                _type: DOC_TYPES.PAGE,
                slug: 'menu',
              },
            },
            breadcrumbLink2: {
              label: data?.menuItemData?.category?.title || title,
              linkType: 'internal',
              link: {
                _type: DOC_TYPES.PAGE,
                slug: MENU_SLUG,
              },
              hash: data?.menuItemData?.category?.slug?.current
                ? getMenuItemListingGroupId(data?.menuItemData?.category?.slug?.current as string)
                : null,
              navigationOffset: true,
            },
            staggeredImages: data?.staggeredMediaContent?.items?.length
              ? data?.staggeredMediaContent?.items
              : allowedStaggeredImages,
            cta: {
              label: geti18nText(language, 'findALocation'),
              linkType: 'internal',
              link: {
                _type: DOC_TYPES.PAGE,
                slug: process.env.NEXT_PUBLIC_IS_CANADA_WEBSITE === 'true' ? 'locations/vancouver' : 'locations',
              },
            },
          },
        ],
      }

      newSections.push(menuItemHero)

      if (data?.textAndImageContent?.isEnabled) {
        let defaultLink: SanityLink = {
          label: geti18nText(language, 'menuTextAndImageDefaultLinkTitle'),
          linkType: 'internal',
          link: {
            slug: LOCATIONS_SLUG,
            _type: DOC_TYPES.PAGE,
          },
        }

        if (data?.textAndImageContent?.cta?.length) {
          defaultLink = data?.textAndImageContent?.cta[0]
        }

        const isArch =
          data?.menuItemData?.heroImageStyle === 'square' || data?.menuItemData?.heroImageStyle === 'roundedCorners'

        const textAndImageData = {
          section: [
            {
              _type: 'textAndImage',
              _id: '29348698236',
              cmsSettings: {
                isHidden: false,
                cmsTitle: 'menu-item-text-and-image',
              },
              sectionId: '2983tr7283g329',
              sectionBackground: 'dough',
              description:
                data?.textAndImageContent?.description || geti18nText(language, 'menuTextAndImageDefaultDescription'),
              title: data?.textAndImageContent?.title || geti18nText(language, 'menuTextAndImageDefaultTitle'),
              eyebrow: null,
              media: {
                image: data?.textAndImageContent?.image || data?.menuItemData?.images[0],
                mediaStyle: isArch ? 'arch' : 'roundedCorners',
                mediaType: 'image',
                videoLoopDesktop: null,
                videoLoopMobile: null,
              },
              textPosition: 'right',
              cta: defaultLink,
            },
          ],
        }

        newSections.push(textAndImageData)
      }
    }

    if (data._type === 'location') {
      const locationHero = {
        section: [
          {
            _type: 'locationHero',
            _id: 'locationHero',
            areaTitle: data?.locationData?.areaTitle || '',
            city: data?.locationData?.city || '',
            cmsSettings: {
              isHidden: false,
              cmsTitle: '',
            },
            emailSubscribeText: data?.locationData?.emailSubscribeText || '',
            eyebrowText: data?.locationData?.eyebrowText || false,
            featuredImage: data?.locationData?.featuredImage || undefined,
            googleMapsLink: data?.locationData?.googleMapsLink || '',
            hasPrivateDining: data?.locationData?.hasPrivateDining || undefined,
            heroDescription: data?.locationData?.heroDescription || [],
            holidayHours: data?.locationData?.holidayHours || [],
            hours: typeof data?.locationData?.hours === 'string' ? JSON.parse(data?.locationData?.hours) : data?.locationData?.hours,
            images: data?.locationData?.images || [],
            isComingSoon: data?.locationData?.isComingSoon || false,
            isReservationOnly: data?.locationData?.isReservationOnly || false,
            isSoftOpening: data?.locationData?.isSoftOpening || false,
            isTemporarilyClosed: data?.locationData?.isTemporarilyClosed || false,
            phoneNumber: data?.locationData?.phoneNumber || '',
            postalCode: data?.locationData?.postalCode || '',
            province: data?.locationData?.province || '',
            reservationLinks: data?.locationData?.reservationLinks || [],
            sectionBackground: 'dough' as SectionBackground,
            serviceLinks: data?.locationData?.serviceLinks || [],
            slug: data?.slug?.current || '',
            state: data?.locationData?.state || '',
            streetAddress: data?.locationData?.streetAddress || '',
            timeZone: data?.locationData?.timeZone || '',
            title: data?.title || '',
            weeklyOpeningHours: data?.locationData?.weeklyOpeningHours,
          },
        ],
      }

      newSections.push(locationHero)
    }

    if (data._type === 'blogPost') {
      const blogPostHero = {
        section: [
          {
            _type: 'blogPost',
            _id: 'blogPost',
            cmsSettings: {
              isHidden: false,
              cmsTitle: '',
            },
            sectionBackground: 'dough' as SectionBackground,
            title: data?.title || '',
            richTextContent: data?.richTextContent,
          },
        ],
      }

      newSections.push(blogPostHero)
    }

    if (!newSections.length) return data.sections

    const sections: SectionsSchema = [...newSections]

    if (data?.sections?.length) {
      data?.sections.forEach((section: SectionsSchema) => {
        sections.push({ section: [section] })
      })
    }

    const filteredSections = sections.filter(section => Object.keys(section.section[0]).length)

    return filteredSections
  }

  return data.sections
}
