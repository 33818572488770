import CanadianLogoHorizontalWhite from '@/components/_svgs/CanadianLogoHorizontalWhite'
import Clock from '@/components/_svgs/Clock'
import CaretRight from '@/components/_svgs/CaretRight'
import FooterSymbol from '@/components/_svgs/FooterSymbol'
import DTF from '@/components/_svgs/DTF'
import CaretDown from '@/components/_svgs/CaretDown'
import HeaderLogo from '@/components/_svgs/HeaderLogo'
import Close from '@/components/_svgs/Close'
import Hamburger from '@/components/_svgs/Hamburger'
import Play from '@/components/_svgs/Play'
import Check from '@/components/_svgs/Check'
import Spinner from '@/components/_svgs/Spinner/Spinner'

const Icons = {
  canadianLogoHorizontalWhite: CanadianLogoHorizontalWhite,
  clock: Clock,
  caretRight: CaretRight,
  caretDown: CaretDown,
  footerSymbol: FooterSymbol,
  dtf: DTF,
  headerLogo: HeaderLogo,
  close: Close,
  hamburger: Hamburger,
  play: Play,
  check: Check,
  spinner: Spinner,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Icon({ name, className, addFilled }: IconProps) {
  const Icon = Icons[name as keyof typeof Icons]
  if (!Icon) return null

  return <Icon className={className} />
}
